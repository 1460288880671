/* src/components/Navbar.css */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .navbar-logo {
    font-size: 1.5rem;
    color: #8b00ff; /* HBO Purple */
    text-decoration: none;
  }
  
  .navbar-links {
    display: flex;
    align-items: center;
  }
  
  .navbar-links a {
    margin-left: 1rem;
    color: #333;
    text-decoration: none;
    font-size: 1rem;
  }
  
  .navbar-links a:hover {
    text-decoration: underline;
  }
  
  .navbar-search {
    margin-left: 1rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .navbar-profile {
    display: flex;
    align-items: center;
    margin-left: 1rem;
  }
  
  .profile-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
  
/* src/App.css */

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5; /* Light grey background for contrast */
  color: #333333; /* Dark grey text */
}

.App {
  text-align: center;
}

header {
  background-color: #2a0d45; /* Deep Purple */
  padding: 1rem 2rem;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

header nav a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  margin: 0 1rem;
}

header nav a:hover {
  text-decoration: underline;
}

main {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero {
  background-color: #2a0d45; /* Deep Purple */
  color: white;
  padding: 5rem 2rem;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.services {
  padding: 2rem;
}

.service-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.service-item {
  background-color: white;
  text-align: center;
  margin: 1rem;
  padding: 1rem;
  flex: 1 1 30%;
  max-width: 30%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-item img {
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.service-item h3 {
  margin-top: 1rem;
  color: #2a0d45; /* Deep Purple */
}

footer {
  background-color: #2a0d45; /* Deep Purple */
  padding: 1rem;
  color: white;
  text-align: center;
  margin-top: 2rem;
}

button {
  background-color: #4b0082; /* Darker Purple */
  color: white;
  border: none;
  padding: 1rem 2rem;
  margin: 1rem 0;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 25px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #350060; /* Even Darker Purple */
}

a {
  text-decoration: none;
  color: #4b0082; /* Darker Purple */
}

a:hover {
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  color: #2a0d45; /* Deep Purple */
}

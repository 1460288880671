/* src/index.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  font-family: 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  color: #333;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-logo {
  font-size: 1.5rem;
  color: #8b00ff; /* HBO Purple */
  text-decoration: none;
}

.navbar-links a {
  margin-left: 1rem;
  color: #333;
  text-decoration: none;
  font-size: 1rem;
}

.navbar-links a:hover {
  text-decoration: underline;
}

footer {
  background-color: #f7f7f7;
  text-align: center;
  padding: 1rem;
  position: relative;
  width: 100%;
}

/* src/components/Booking.css */

main {
  padding: 2rem;
}

h1 {
  color: #2a0d45; /* Deep Purple */
}

p {
  font-size: 1.2rem;
  margin: 1rem 0;
}

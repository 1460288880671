/* src/components/ServiceList.css */
.service-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 2rem 0; /* Ensure some padding */
}

.service-item {
  text-align: center;
  margin: 1rem;
  flex: 1 1 30%;
  max-width: 30%;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  padding: 1rem;
}

.service-item img {
  max-width: 100%;
  border-radius: 10px;
}

.service-item h3 {
  margin-top: 1rem;
  color: #2a0d45;
}

.service-item button {
  background-color: #8b00ff; /* HBO Purple */
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 25px;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

.service-item button:hover {
  background-color: #6200b3; /* Darker Purple */
}

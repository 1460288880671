/* src/components/Login.css */

form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: auto;
  }
  
  label {
    margin-bottom: 1rem;
  }
  
  button {
    background-color: #4b0082;
    color: white;
    border: none;
    padding: 1rem 2rem;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 25px;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #350060;
  }
  
/* src/components/Home.css */
.home-hero {
  background-image: url("https://vanity-inc.com/static/media/Gabriel%20Dugu%C3%A9%20-%20Collage.9b99f740cc8c23b09402.gif");
  background-size: cover;
  background-position: center;
  padding: 5rem 2rem;
  text-align: center;
  color: white;
  height: calc(100vh - 80px); /* Full viewport height minus navbar height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.home-hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.home-hero p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.hero-button {
  background-color: #8b00ff; /* HBO Purple */
  color: white;
  border: none;
  padding: 1rem 2rem;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 25px;
  transition: background-color 0.3s ease;
}

.hero-button:hover {
  background-color: #6200b3; /* Darker Purple */
}

.home-services {
  padding: 2rem;
  text-align: center;
}

.home-services h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
}

/* Horizontal scrolling for service list */
.service-list {
  display: flex;
  flex-direction: row;  /* Ensure the items are in a single row */
  overflow-x: auto;  /* Enable horizontal scrolling */
  padding: 1rem 0;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: #8b00ff transparent; /* Customize scrollbar color */
}

.service-list::-webkit-scrollbar {
  height: 8px; /* Customize scrollbar height */
}

.service-list::-webkit-scrollbar-thumb {
  background-color: #8b00ff; /* Customize scrollbar color */
  border-radius: 10px;
}

.service-item {
  flex: 0 0 auto;
  width: 200px; /* Adjust width as needed */
  text-align: center;
  margin-right: 1rem;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  padding: 1rem;
  scroll-snap-align: start;
  transition: transform 0.3s ease;
}

.service-item:hover {
  transform: scale(1.05);
}

.service-item img {
  max-width: 100%;
  border-radius: 10px;
}

.service-item h3 {
  margin-top: 1rem;
  color: #2a0d45;
}

.service-item button {
  background-color: #8b00ff; /* HBO Purple */
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 25px;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

.service-item button:hover {
  background-color: #6200b3; /* Darker Purple */
}

/* Responsive styles */
@media (max-width: 768px) {
  .home-hero {
    padding: 3rem 1rem;
    height: auto; /* Adjust height for mobile */
  }

  .home-hero h1 {
    font-size: 2rem;
  }

  .home-hero p {
    font-size: 1.2rem;
  }

  .hero-button {
    padding: 0.8rem 1.6rem;
    font-size: 0.9rem;
  }

  .service-item {
    flex: 0 0 80%;
    max-width: 80%;
    margin-right: 1rem;
  }
}
